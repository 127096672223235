import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Col from "components/Common/Col";
import LoginButtonContainer from "components/Layout/Header/LoginButtonContainer";
import UserContainer from "components/Layout/Header/UserContainer";
import ProfileImage from "components/Layout/ProfileMenu/ProfileImage";
import ProfileMenu from "components/Layout/ProfileMenu/ProfileMenuAsync";

import HeaderNotifications from "./HeaderNotificationsAsync";

import authActions from "actions/auth";
import paginationActions from "actions/pagination";
import { selectAuthLoggingIn } from "selectors/auth";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser, { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import HeaderStyles, { showRegisterAbove } from "styles/HeaderStyles";

const whiteNavBarStyles = {
  menuIcon: {
    color: colours.black,
  },
};

const HeaderUser = (props) => {
  const {
    onSearchToggleClick,
    onSearchToggleKeyDown,
    hideSearchToggle,
    switchToWhiteStyles,
  } = props;

  const isLoggedIn = useLoggedIn();
  const user = useLoggedInUser();

  const { startAuthFlow } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
    replaceList: paginationActions.replaceList,
  });

  const { isWindowWidthOrMore, isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");

  const showRegister = isWindowWidthOrMore(showRegisterAbove);

  const { styles } = useStyles(HeaderStyles, props);

  const isLoggingIn = useReduxState((state) => selectAuthLoggingIn(state), []);

  const handleMobileLoginClick = useCallback(
    () =>
      startAuthFlow(undefined, {
        analyticsVariables: {
          triggered_by: "mobileHeaderLoginButton",
        },
      }),
    [startAuthFlow]
  );

  const renderNotifications = () => (
    <HeaderNotifications switchToWhiteStyles={switchToWhiteStyles} />
  );

  return (
    <Col
      className={css(
        styles.userDetailsColumn,
        isMediumOrLess && styles.userDetails
      )}
    >
      {isLoggedIn && renderNotifications()}
      {!hideSearchToggle && !isMediumOrLess && (
        <a
          className={css(styles.searchToggle, styles.searchToggleRight)}
          onClick={onSearchToggleClick}
          onKeyDown={onSearchToggleKeyDown}
        >
          <FontAwesomeIcon
            icon={faSearch}
            className={css(styles.searchToggleIcon)}
          />
        </a>
      )}
      {isLoggingIn && <ProfileImage loading />}
      {!isLoggingIn && (
        <Fragment>
          {!isLoggedIn && showRegister && (
            <div className={css(styles.buttons)}>
              <LoginButtonContainer />
            </div>
          )}
          {!isLoggedIn && !showRegister && (
            <FontAwesomeIcon
              data-id="header-login-button"
              icon={faUser}
              className={css(styles.mobileLoginButton)}
              onClick={handleMobileLoginClick}
            />
          )}
          {isLoggedIn && (
            <UserContainer key={user.get("id")}>
              {user ? (
                <ProfileMenu
                  user={user}
                  key={user.get("id")}
                  styles={switchToWhiteStyles ? whiteNavBarStyles : null}
                />
              ) : (
                <ProfileImage loading />
              )}
            </UserContainer>
          )}
        </Fragment>
      )}
    </Col>
  );
};

HeaderUser.propTypes = {
  onSearchToggleClick: PropTypes.func.isRequired,
  onSearchToggleKeyDown: PropTypes.func.isRequired,
  hideSearchToggle: PropTypes.bool,
  switchToWhiteStyles: PropTypes.bool,
};

HeaderUser.defaultProps = {
  hideSearchToggle: false,
};

export default memo(HeaderUser);
